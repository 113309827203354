require("typeface-playfair-display");
require("typeface-fauna-one");
import {AnimatePresence} from "framer-motion";
import {DefaultSeo} from 'next-seo';
import Script from 'next/script'
import SEO from './../next-seo.config';
import "../styles/globals.scss";

const googleAnalyticsScript = () => {
  if (process.env.NEXT_PUBLIC_IS_PRODUCTION === "yeah") {
    const trackingId = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID;

    return (
      <>
        <Script
          src={`https://www.googletagmanager.com/gtag/js?id=${trackingId}`}
          strategy="afterInteractive"
        />
        <Script id="google-analytics" strategy="afterInteractive">
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${trackingId}');
          `}
        </Script>
      </>
    )
  }
};

const MyApp = ({Component, pageProps, router}) => {
  return (
    <>
      {googleAnalyticsScript()}
      <AnimatePresence mode='wait'>
        <DefaultSeo {...SEO} />
        <Component {...pageProps}
          // key is needed for framer motion
          key={router.route}
        />
      </AnimatePresence>
    </>
  );
};

export default MyApp;
