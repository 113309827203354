export default {
  dangerouslySetAllPagesToNoFollow: process.env.NEXT_PUBLIC_IS_PRODUCTION !== "yeah",
  dangerouslySetAllPagesToNoIndex: process.env.NEXT_PUBLIC_IS_PRODUCTION !== "yeah",
  title: "Axel Thiele. Architekt. Energieberater. Zimmerermeister. Niedenstein",
  description: "Architekt, Energieberater and Zimmerermeister service. Niedenstein",
  openGraph: {
    type: "website",
    locale: "de-de",
    url: "https://axel-thiele.de/",
    site_name: "Axel Thiele. Architekt. Energieberater. Zimmerermeister. Niedenstein",
  },
}
